import $ from 'jquery';
import logger from 'OK/logger';
import okVideoPlayerUtils from 'okVideoPlayerUtils';
import { ajax, updateBlockModelCallback, unescapeWebUrl, extendDeep } from 'OK/utils/utils';
import music from 'OK/music2/app';
import { EPISODE_CLICKED, EPISODE_CHANGED } from 'OK/VideoPlayerEventBuses';

/**
 * Movie status enum
 * @see one/video/services/movie/MovieStatus.java
 */
var MovieStatus = {
    UNKNOWN: 'UNKNOWN', //ролик в непонятном, неконсистентном состоянии
    OK: 'OK', //успешно загружено/обработано/отмодерировано
    ERROR: 'ERROR', //во время загрузки/обработки произошла ошибка
    UPLOADING: 'UPLOADING', //загружается (>> PROCESSING || >> ERROR)
    CREATING: 'CREATING', // ALIAS UPLOADING для LIVE_TV (>> OK || >> OFFLINE)
    PROCESSING: 'PROCESSING', //обрабатывается (>> OK || >> ON_MODERATION || >> ERROR)
    ONLINE: 'ONLINE', // трансляция играет
    OFFLINE: 'OFFLINE', // трансляция приостановлена
    LIVE_NOT_STARTED: 'LIVE_NOT_STARTED', // трансляция не началась (currentTime < startTime)
    LIVE_ENDED: 'LIVE_ENDED', // трансляция закончена (endTime < currentTime)
    LIVE_INTERRUPTED: 'LIVE_INTERRUPTED',  // трансляция временно приостановлена
    ON_MODERATION: 'ON_MODERATION', //на модерации (>> OK || >> BLOCKED || >> CENSORED || >> COPYRIGHTS_RESTRICTED)
    BLOCKED: 'BLOCKED', //ролик заблокирован
    CENSORED: 'CENSORED', //не прошел модерацию
    COPYRIGHTS_RESTRICTED: 'COPYRIGHTS_RESTRICTED', //заблокировано по требованию правообладателей
    UNAVAILABLE: 'UNAVAILABLE', //ролик недоступен на внешнем ресурсе
    LIMITED_ACCESS: 'LIMITED_ACCESS' //доступ к ролику ограничен пользователем или не принятны условия лицензионного соглашения
};

/**
 * Mini-player id.
 * @type {string}
 */
var MINI_PLAYER_ID = createPlayerElementId('MiniVideoPlayer');

/**
 * Video status with no flash installed.
 * @type {string}
 */
var STATUS_NO_FLASH = 'no_flash_installed';

/**
 * Video not found status.
 * @type {string}
 */
var STATUS_NOT_FOUND = 'not_found';

/**
 * Active player id.
 * @type {string}
 * @private
 */
var _activePlayerId = null;

/**
 * Active player replacement card id.
 * @type {string}
 * @private
 */
var _activeContainerId = null;

/**
 * Active player options.
 * @type {{}}
 * @private
 */
var _activePlayerOptions = {};

/**
 * Active preactivated video element ID. Used only on mobile.
 * @type {{}}
 * @private
 */
var _activeVideoElementId;

/**
 * Retry timeout for live stream.
 * @type {number}
 * @private
 */
var _activeRetryTimeout = null;

/**
 * Start timeout for live stream.
 * @type {number}
 * @private
 */
var _activeStartTimeout = null;

/**
 * End timeout for live stream.
 * @type {number}
 * @private
 */
var _activeEndTimeout = null;

var translations = {};

var loaderInfo = {}

// Останавливаем воспроизведение видео при запуске музыки
$(window).on('onMusicPlaying', function() {
    if (_activePlayerOptions.isExternalPlayer) {
        stop();
    } else {
        pause(true);
    }
});

/**
 * Fallback timeout for showing player, when new player events does not exist.
 * To remove after release player with new events
 * @type {number}
 * @private
 */
var _showPlayerFallbackTimeout = null;

document.addEventListener('__videoPlayerEvent', function(e) {
    if (!e.detail) {
        return;
    }

    var eventName = e.detail.name;
    var eventData = e.detail.data;

    if (eventName === 'episodeChanged' && eventData) {
        EPISODE_CHANGED.emit({
            movieId: e.detail.data.movieId,
            time: e.detail.data.value.time,
        });
    }

    if (eventName === 'episodeClicked' && eventData) {
        if (!_activePlayerOptions.flashvars.isLayer) {
            var url = _activePlayerOptions.flashvars.metadata.movie.link;
            url += "&st.vpl.ioep=true";
            url += "&st.vpl.ft=" + document.getElementById(_activePlayerId).getCurrentTime();
            window.navigateOnUrlFromJS(unescapeWebUrl(url));
        } else {
            EPISODE_CLICKED.emit({
                movieId: e.detail.data.movieId,
            });
        }
    }

    if ((eventName === 'adv' && eventData.value === 'impression') || eventData.providerId === 'USER_YOUTUBE') {
        // Ютуб и рекламу показываем сразу
        // impression - момент первого кадра рекламы
        _showPlayerWhenDataReady();
    }

    // Остальные ролики показываем только когда есть первый кадр
    if (eventName === 'first_frame') {
        _showPlayerWhenDataReady();
    }

    // Если нет новых типов событий и/или не успели показать за 900ms без мелькания,
    // то принудительно покажем плеер
    if (eventName === 'started') {
        _showPlayerFallbackTimeout = setTimeout(function () {
            _showPlayerWhenDataReady();
        }, 900);
    }
});

function _showPlayerWhenDataReady() {
    clearTimeout(_showPlayerFallbackTimeout);

    var playerContainer = getPlayerContainer();
    if (playerContainer) {
        playerContainer.classList.add('invisible');
    }

    var player = getPlayer();
    if (player) {
        player.classList.remove('__hidden');
    }
}

/**
 * Проверяет, в фуллскрине сейчас
 * @return {boolean}
 */
function _isInFullscreen() {
    return !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement);
}

/**
 * Выходит из полноэкранного режима
 * @private
 */
function _exitFullscreen() {
    if (!_isInFullscreen()) {
        return;
    }
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}

/**
1    * Render error.
 * @param {{}} options
 * @param {string} playerContainerId
 * @param {string} playerElementId
 * @param {string} status
 * @param {string=} errorText
 * @private
 */
function _renderPlayerError(options, playerContainerId, playerElementId, status, errorText) {
    var $container = $('#' + playerContainerId),
        $element = $('#' + playerElementId);

    _exitFullscreen(); // Если открыт плеер, то нужно выйти из фуллскрина

    // Создаём элемент вместо которого будет вставлен контейнер ошибки и вставляем его после контейнера карды
    if (!$element.length) {
        $element = $('<div>', {'class': 'vp_video_error'}, {id: playerElementId}).insertAfter($container);
    } else {
        $element.empty();
    }

    var $errorContainer = $('<div/>', {'class': 'vp_video_stub_w'}),
        $errorWrapper = $('<div/>', {'class': 'vp_video_stub __na'}).appendTo($errorContainer);
    $('<div/>', {'class': 'vp_video_stub_txt', 'html': (errorText || translations[status])}).appendTo($errorWrapper);

    $container.addClass('invisible'); // Прячем контейнер, чтобы потом можно было его вернуть
    $element.removeClass('invisible').append($errorContainer);

    if (options.poster) {
        $('<img/>', {'width': '100%', 'src': options.poster, 'class': 'vp-video_stub_i'}).insertBefore($errorContainer);
    }
}

/**
 * Stop all timeouts for acrive player.
 * @private
 */
function _stopTimers() {
    if (_activeRetryTimeout) {
        clearTimeout(_activeRetryTimeout);
        _activeRetryTimeout = null;
    }
    if (_activeStartTimeout) {
        clearInterval(_activeStartTimeout);
        _activeStartTimeout = null;
    }
    if (_activeEndTimeout) {
        clearTimeout(_activeEndTimeout);
        _activeEndTimeout = null;
    }
}

/**
 * Cleanup flashvars.
 * @param {{}} flashvars
 * @return {{}}
 * @private
 */
function _cleanupFlashvars(flashvars) {
    for (var i in flashvars) if (flashvars.hasOwnProperty(i)) {
        if (typeof flashvars[i] !== 'string') {
            flashvars[i] = encodeURIComponent(JSON.stringify(flashvars[i]));
        }
    }
    return flashvars;
}

/**
 * Log actionPlay for opengraph movies.
 * @param {{}} options
 * @private
 */
function _logOpenGraph(options) {
    options.isExternalPlayer && $.ajax({
        url: '/dk?cmd=videoStatNew',
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
            duration: options.flashvars.metadata.movie.duration,
            movieId: options.flashvars.metadata.movie.movieId,
            contentId: options.flashvars.metadata.movie.contentId,
            location: options.flashvars.location,
            providerId: options.provider,
            batch: [{name: 'started'}]
        })
    }).fail(function() {
        logger.error('OKVideoStart', 'failOG');
    });
}

/**
 * Check video is purchased or free.
 * @param {{}} options
 * @return {boolean}
 * @private
 */
function _isPurchasedOrFree(options) {
    var movie = options.flashvars.metadata.movie;
    if (movie) {
        return !movie.paymentStatus || movie.paymentStatus === 'PAID';
    }

    return true;
}

/**
 * Get metadata.
 * @param {?string} metadataUrl
 * @param {?{}} data
 * @param {function=} success
 * @param {function=} error
 * @private
 * @return {$.Deferred}
 */
function _getMetadata(metadataUrl, data, success, error) {
    return $.ajax({
        url: metadataUrl ? decodeURIComponent(metadataUrl) : '/dk?cmd=videoPlayerMetadata',
        data: data,
        dataType: 'json',
        type: 'POST',
        headers: {'TKN': OK.tkn.get()}
    }).done(success).fail(error);
}

/**
 * Get metadata and run callback.
 * @param {{}} options
 * @param {function} successCallback
 * @param {function} errorCallback
 * @param {boolean=} isSmall
 * @private
 */
function _getMetadataAndRun(options, successCallback, errorCallback, isSmall) {
    var metadataUrl = options.flashvars.metadataUrl,
        data = {};

    // Если нет metadataUrl - запрашиваем по movieId
    if (!metadataUrl) {
        if (options.flashvars.metadata) {
            data.mid = options.flashvars.metadata.movie.movieId;
        } else {
            if (errorCallback) {
                errorCallback();
            }
            return;
        }
    }

    if (isSmall && options.flashvars.metadata) {
        // Маленькая метадата доступна только если уже есть полная
        data.is = 'on';
    } else {
        // Запрашиваем полную метадату - старые данные не нужны
        options.flashvars.metadata = {};
    }

    data['st.location'] = options.flashvars.location;

    // Получаем метадату
    _getMetadata(
        metadataUrl,
        data,
        function(metadata) {
            if (metadata && !metadata.error) {
                $.extend(true, options.flashvars.metadata, metadata);
                if (options.blockWebrtc) {
                    delete options.flashvars.metadata.webrtcUrl;
                }
                delete options.flashvars.metadataUrl; // Чтобы плеер не ходил второй раз за метадатой
                if (successCallback) {
                    successCallback();
                }
            } else if (errorCallback) {
                errorCallback();
            }
        },
        function() {
            if (errorCallback) {
                errorCallback();
            }
        }
    );
}

/**
 * Create a preactivated video element if necessary and always run callback.
 * @param {{}} options
 * @param {element} parent
 * @param {function} callback
 * @private
 */
function _preactivateVideo(options, parent, callback) {
    if (parent && options.stubEnabled) {
        var video;
        try {
            var stubUrl = OK.cnst.staticUrl + 'res/i/video/stub.mp4';
            // On mobile devices browsers require starting video playback
            // in click context. We create a video element and start
            // playback of a "stub" video which activates the video tag
            // and we can later reuse it for video playback without click context
            video = document.createElement('video');
            var playsInline = 1;
            if (options.verifyInline) {
                // on iOS < 10 video can not be played inline
                // so we will not attempt autoplay
                playsInline = video && 'playsInline' in video;
            }
            if (video && playsInline) {
                parent.appendChild(video);
                var source = document.createElement('source');
                source.setAttribute('src', stubUrl);
                var videoID = 'act_video_' + Math.round(1E9 * Math.random());
                video.id = videoID;
                video.setAttribute('playsInline', '1');
                video.appendChild(source);
                video.load();
                video.play()
                        .then(callback.bind(null, videoID))
                        ['catch'](function() {
                            if (video) {
                                parent.removeChild(video);
                            }
                            callback(null);
                        });
                return;
            }
        } catch (e) {
            // if we are not in click context, fallback to 2 clicks
            if (video) {
                parent.removeChild(video);
            }
        }
    }
    callback();
}

/**
 * Возвращает существующую метадату или запрашивает её при отсутствии.
 * @param {{}} options
 * @param {function} successCallback
 * @param {function} errorCallback
 * @private
 */
function _getExistsMetadataAndRun(options, successCallback, errorCallback) {
    if (options.flashvars.metadata) {
        // Если метадата представлена строкой - распарсим её
        if (typeof options.flashvars.metadata === 'string') {
            options.flashvars.metadata = JSON.parse(options.flashvars.metadata);
        }
        if (successCallback) {
            successCallback();
        }
    } else {
        _getMetadataAndRun(options, successCallback, errorCallback);
    }
}

/**
 * Get VideoPlayerAttributes.
 * @param {string} movieId
 * @param {string} location
 * @return {$.Deferred}
 * @private
 */
function _getPlayerAttributes(movieId, location) {
    return ajax({
        url: '/dk',
        data: {
            'cmd': 'videoCommand',
            'a': 'getVideoPlayerAttributes',
            'st.vv_movieId': movieId,
            'st.location': location
        },
        dataType: 'json',
        type: 'POST',
        headers: {'TKN': OK.tkn.get()}
    });
}

/**
 * Unload css link tag from page.
 * @param {string} url
 * @private
 */
function _unloadCss(url) {
    var link = document.querySelector('link[href*=\'' + url + '\']');
    if (link) {
        link.disabled = true;
        link.parentNode.removeChild(link);
    }
}

/**
 * Embed html5 player.
 * @param {{}} options
 * @param {string} elementId - ID элемента, который будет заменён плеером
 * @param {string} activatedVideoId - ID видео элемента который был активирован в click контексте
 * @private
 */
function _embedJsPlayer(options, elementId, activatedVideoId) {
    function runOldPlayer() {
        var MODULE = 'okHtml5Player',
            config = {paths: {'okHtml5Player': options.html5url}},
            loaded = requirejs.defined(MODULE);

        if (loaded) {
            var loadedJS = requirejs.toUrl(MODULE),
                loadedCSS = requirejs.toUrl(MODULE + '.css');

            if (loadedJS !== options.html5url) {
                _unloadCss(loadedCSS);
                requirejs.undef(MODULE);
                requirejs.config(config);
            }
        } else {
            requirejs.config(config);
        }

        import(MODULE).then(m => m && m.default || m).then(function(okHtml5Player) {
            okHtml5Player.embedPlayer(elementId, elementId, options.flashvars, activatedVideoId);
        });
    }
    if (options.okVideoPlayerEnabled) {
        var fallback = function (e) {
            runOldPlayer();
            logger.clob('error', e && e.stack, 'one-video-player', options.flashvars.metadata.movie.id);
        };
        import('one-video-player').then(m => m && m.default || m).then(function(OneVideoPlayer) {
            OneVideoPlayer.create(elementId, options, activatedVideoId).catch(fallback);
        }, fallback);
    } else {
        runOldPlayer();
    }
}

/**
 * Embed flash player.
 * @param {{}} options
 * @param {string} elementId - ID элемента, который будет заменён плеером
 * @private
 */
function _embedSwfPlayer(options, elementId) {
    import('swfobject').then(m => m && m.default || m).then(function(swfobject) {
        if (swfobject.getObjectById(elementId)) {
            return;
        }

        var swfUrl = swfobject.hasFlashPlayerVersion(options.minFlashVersionNewPlayer || '11.2.0') && options.url11 ? options.url11 : options.url;
        var attributes = {id: elementId, name: elementId};
        var params = {
            allowScriptAccess: options.asa ? 'always' : 'never',
            wmode: options.wmode || 'opaque',
            allowFullScreen: true,
            menu: false,
            bgcolor: '#000000'
        };

        var flashvars = {
            autoplay: true,
            wmode: params.wmode,
            stageVideo: params.wmode === 'direct' || params.wmode === 'gpu',
            playerId: options.playerId
        };

        // Добавляем наши флешвары только для наших роликов - для opengraph они не нужны
        if (!options.isExternalPlayer) {
            flashvars = _cleanupFlashvars($.extend({}, options.flashvars, flashvars));
        }

        swfobject.embedSWF(swfUrl, elementId, '100%', '100%', '10.0.0', null, flashvars, params, attributes);
    });

    _logOpenGraph(options);
}

/**
 * Embed external iframe player.
 * @param {{}} options
 * @param {string} elementId - ID элемента, который будет заменён плеером
 * @private
 */
function _embedIframePlayer(options, elementId) {
    var iframe = document.createElement('iframe');
    iframe.id = elementId;
    iframe.className = 'vid-card_player';
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.tabIndex = '0';
    iframe.frameBorder = 'no';
    iframe.scrolling = 'no';
    iframe.allowFullScreen = true;
    iframe.setAttribute('allowfullscreen', ''); // Safari работает только так
    iframe.src = options.url;
    var containerElement = document.getElementById(elementId);
    containerElement.parentNode.replaceChild(iframe, containerElement);

    _logOpenGraph(options);
}

/**
 * Embed live stream stub with timer.
 * @param {{}} options
 * @param {string} playerContainerId
 * @param {string} playerElementId
 * @private
 */
function _embedLiveStub(options, playerContainerId, playerElementId) {
    var metadata = options.flashvars.metadata,
        liveStreamInfo = metadata.liveStreamInfo,
        status = metadata.movie.status,
        now = Date.now() + options.timeshift,
        start = parseInt(liveStreamInfo.startTime, 10);

    function formatTimeRange(from, to) {
        var time = ~~((from - to) / 1000),
            h = ~~(time / 3600),
            m = ~~(time / 60) % 60,
            s = time % 60;
        return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
    }

    /**
     * @param {{}} options
     * @param {string} iconClass
     * @param {string} buttonTextKey ключ ПТС
     * @param {string} action действие по клику на кнопку ('subscribe' или 'unsubscribe')
     * @param {string} aid activityId
     */
    function createNotifyButton(options, iconClass, buttonTextKey, action, aid) {
        // кнопка "оповестить / не оповещать" о начале трансляции
        var $bcButton = $('<div/>', {'class': 'vp_broadcast-stub_ac button-pro'});

        // иконка ic_notifications-on или ic_notifications-off
        $('<div/>', {'class': iconClass}).appendTo($bcButton);
        $('<div/>', {'class': 'vp_broadcast-stub_ac_tx', 'text': translations[buttonTextKey]}).appendTo($bcButton);

        $bcButton.on('click', function() {
            OK.VideoPlayer.toggleSubscriptionFromFlash(options, metadata.movie.albumId, metadata.movie.movieId, action, function() {
                $bcButton.addClass('invisible').siblings('.vp_broadcast-stub_ac').removeClass('invisible');
            }, aid);

        });

        return $bcButton;
    }

    // Создаём заглушку
    var $bcContainer = $('<div/>', {'id': playerElementId, 'class': 'vp_broadcast-stub_w'}),
        $bcWrapper = $('<div/>', {'class': 'vp_video_stub_w'}).appendTo($bcContainer),
        $bcMain = $('<div/>', {'class': 'vp_broadcast-stub'}).appendTo($bcWrapper),
        $bcText = $('<div/>', {'class': 'vp_broadcast-stub_tx'}).appendTo($bcMain);

    $('<img/>', {'width': '100%', 'src': options.poster, 'class': 'vid-card_img'}).appendTo($bcContainer);
    $('#' + playerElementId).replaceWith($bcContainer);

    if (status === MovieStatus.OFFLINE) { // Трансляция приостановлена
        $bcText.html(translations['live_stream_offline']);
        retry(options.liveRertyTimeout, true);
    } else if (start > now) { // Таймер до начала трансляции
        $bcText.html(translations['live_stream_after']);
        var $time = $('<div/>', {'class': 'vp_broadcast-stub_time js-live-time', 'text': formatTimeRange(start, now)}).appendTo($bcMain);
        _activeStartTimeout = setInterval(function() {
            now = Date.now() + options.timeshift;
            if (start < now) {
                clearInterval(_activeStartTimeout);
                retry(0, true);
                return;
            }
            $time.text(formatTimeRange(start, now));
        }, 1000);

        if (options.notifyEnabled && metadata.subscribed === false && (options.notifyMovieSubscription ? metadata.movie.movieId : metadata.movie.albumId)) {

            var $bcButtonContainer = $('<div/>').appendTo($bcMain);
            createNotifyButton(options, 'vp_broadcast-stub_ac_ic ic_notifications-on-w', 'notify', 'subscribe', 'Video_NotifyLive').appendTo($bcButtonContainer);
            createNotifyButton(options, 'vp_broadcast-stub_ac_ic ic_notifications-off-w', 'not-notify', 'unsubscribe', 'Video_NotNotifyLive').addClass('invisible').appendTo($bcButtonContainer);
        }
    } else {
        $bcText.html(translations['live_stream_ended']);
    }
}

function _showPayment(paymentInfo, callback) {
    if (document.getElementById('hook_Cfg_CurrentUser')) {
        var url = window.location.href;
        if (url.indexOf('?') < 0) {
            url += "?";
        } else {
            url+="&";
        }
         url += "cmd=PopLayer";
         url += "&st.cmd=" + OK.getCurrentDesktopModelId();
         url += "&st.layer.cmd=PopLayerPaymentWizardOuter";
         url += "&st.layer.timestamp=" + Date.now();
         url += "&st.layer.appId=" + encodeURIComponent(paymentInfo.appId);
         url += "&st.layer.appDescription=";
         url += "&st.layer.appName=" +  encodeURIComponent(paymentInfo.title);
         url += "&st.layer.appCode=" + encodeURIComponent(paymentInfo.productCode);
         url += "&st.layer.currency=" + encodeURIComponent('OK');
         url += "&st.layer.appPrice=" + encodeURIComponent(paymentInfo.price);
         url += "&st.layer.appOptions=";
         url += "&st.layer.appAttributes=";
         url += "&st.layer.appUiConf=";
         url += "&st.layer.callback=true";
         url += "&st.layer.srv=22";
         url += "&st.layer.appOptionCode=";
         url += "&st.layer.appCardOnly=off";
         navigateOnUrlFromJS(url);

        if (callback) {
            OK.loader.use(['OKCustomJs'], function() {
                OK.Layers.subscribe('modal_hook', function layerCb(status) {
                    if (!status) {
                        OK.Layers.unsubscribe('modal_hook', layerCb);
                        callback(true);
                    } else {
                        callback(false);
                    }
                });
            });
        }
    }
}

/**
 * Buy video.
 * @param {{}} options
 * @param {string} playerContainerId
 * @param {string} playerElementId
 * @param {string} activatedVideoId
 * @private
 */
function _openPayment(options, playerContainerId, playerElementId, activatedVideoId) {
    var paymentInfo = options.flashvars.metadata.paymentInfo;

    if (document.getElementById('hook_Cfg_CurrentUser')) {
        _showPayment(paymentInfo, function(status) {
                if (status) {
                    _getMetadataAndRun(options, function() {
                        if (_isPurchasedOrFree(options)) {
                            _embedPlayer(options, playerContainerId, playerElementId, activatedVideoId);
                        }
                    });
                }
        });
    } else if (options.flashvars.isEmbed === '1') {
        // Эмбед, открываем портал
        window.open(options.flashvars.metadata.movie.url, '_blank');
    } else {
        // Анонимка, открываем форму логина
        import('OK/AuthLoginPopup').then(m => m && m.default || m).then(function(authLoginPopupModule) {
                authLoginPopupModule.open({
                        href: decodeURIComponent(OK.historyManager.getState())
                    }
                );
            }
        );
    }
}

function prolongPayment(paymentInfo, updateBlockUrl) {
    _showPayment(paymentInfo, !updateBlockUrl ? null : function(status) {
            if (status) {
                ajax({
                    url: updateBlockUrl
                }).done(updateBlockModelCallback);
            }
    });
}

/**
 * Вызывает метод плеера
 * @param {string} method
 * @param {*} data
 * @private
 */
function _callPlayer(method, data) {
    var player = getPlayer();
    if (player && player.call) {
        player.call(method, data);
    }
}

/**
 * Embed player.
 * @param {{}} options
 * @param {string} playerContainerId
 * @param {string} playerElementId
 * @param {string} activatedVideoId
 * @private
 * ВАЖНО: options уже должны содержать метадату в options.flashvars.metadata
 */
function _embedPlayer(options, playerContainerId, playerElementId, activatedVideoId) {
    options.flashvars.translations = translations;

    var metadata = options.flashvars.metadata;

    if (!metadata || metadata.error) {
        _renderPlayerError(options, playerContainerId, playerElementId, metadata && metadata.error || STATUS_NOT_FOUND);
        return;
    }

    // Если открыт мини-плеер, играем в нём
    if (playerElementId !== _activePlayerId && hasMiniPlayer()) {
        ajax({
            url: unescapeWebUrl(options.flashvars.metadata.movie.link),
            data: {'st.vpl.mini': true, 'st.vpl.ipl': true}
        }).done(updateBlockModelCallback).done(function() {
            if (!hasMiniPlayer()) {
                // Плеер не открылся, возможно нет доступа, повторим попытку воспроизведения
                _embedPlayer(options, playerContainerId, playerElementId, activatedVideoId);
            }
        });
        return;
    }

    var canDash = okVideoPlayerUtils.dash,
        canMP4 = okVideoPlayerUtils.mp4,
        canHLS = okVideoPlayerUtils.hls,
        canWebrtc = okVideoPlayerUtils.webrtc && !options.webrtcBrokenH264,
        hasFlash = okVideoPlayerUtils.flash.major >= 10,
        hasDash = !!(options.flashvars.metadata && options.flashvars.metadata.metadataEmbedded),
        hasHLS = !!(options.flashvars.metadata && options.flashvars.metadata.hlsManifestUrl),
        hasLiveHLS = !!(options.flashvars.metadata && options.flashvars.metadata.hlsMasterPlaylistUrl),
        hasLiveDash = !!(options.flashvars.metadata && options.flashvars.metadata.liveDashManifestUrl),
        hasLiveRtmp = !!(options.flashvars.metadata && options.flashvars.metadata.rtmpUrl),
        hasLiveWebrtc = !!(options.flashvars.metadata && options.flashvars.metadata.webrtcUrl),
        hasLiveStream = !!(options.flashvars.metadata && options.flashvars.liveStream),
        isEmptyPlayer = !!(options.flashvars.metadata && options.flashvars.metadata.emptyPlayer),
        liveStreamInfo = options.flashvars.metadata.liveStreamInfo,
        status = options.flashvars.metadata.movie && options.flashvars.metadata.movie.status,
        statusText = options.flashvars.metadata.movie && options.flashvars.metadata.movie.statusText,
        paymentStatus = options.flashvars.metadata.movie && options.flashvars.metadata.movie.paymentStatus,
        usingVideoTag,
        $container = $('#' + playerContainerId),
        $element = $('#' + playerElementId);

    function _playIframeOrDie() {
        try {
            _embedIframePlayer(options, playerElementId);
        } catch (e) {
            _renderPlayerError(options, playerContainerId, playerElementId, STATUS_NOT_FOUND); // Unknown error
        }
    }

    function _playMP4orDie() {
        if (canMP4) {
            _embedJsPlayer(options, playerElementId, activatedVideoId);
            usingVideoTag = 1;
        } else {
            _renderPlayerError(options, playerContainerId, playerElementId, STATUS_NO_FLASH);
        }
    }

    function _playLive() {
        if (hasLiveWebrtc && canWebrtc) {
            _embedJsPlayer(options, playerElementId, activatedVideoId);
            usingVideoTag = 1;
        } else if (hasLiveRtmp && hasFlash) {
            _playSWForDie();
        } else if ((canDash && hasLiveDash) || (canHLS && hasLiveHLS)) {
            _embedJsPlayer(options, playerElementId, activatedVideoId);
            usingVideoTag = 1;
        } else {
            _playSWForDie();
        }
    }

    function _playSWForDie() {
        if (!hasFlash) {
            _renderPlayerError(options, playerContainerId, playerElementId, STATUS_NO_FLASH);
        } else {
            _embedSwfPlayer(options, playerElementId);
        }
    }

    function _playDashHLSOrAny() {
        if ((canDash && hasDash) || (canHLS && hasHLS)) {
            _embedJsPlayer(options, playerElementId, activatedVideoId);
            usingVideoTag = 1;
        } else if (hasDash && hasFlash) {
            _embedSwfPlayer(options, playerElementId);
        } else if (canMP4) {
            _playMP4orDie();
        } else {
            _playSWForDie();
        }
    }

    function _playVideo() {
        if (isEmptyPlayer) {
            _embedJsPlayer(options, playerElementId, activatedVideoId);
        } else if (options.isIframePlayer) {
            _playIframeOrDie();
        } else if (options.isExternalPlayer || !options.isHtml5Player) {
            _playSWForDie();
        } else if (hasLiveDash || hasLiveHLS || hasLiveRtmp || hasLiveWebrtc) {
            _playLive();
        } else if (hasDash || hasHLS) {
            _playDashHLSOrAny();
        } else if (canMP4) {
            _embedJsPlayer(options, playerElementId, activatedVideoId);
            usingVideoTag = 1;
        } else {
            _playSWForDie();
        }
        if (activatedVideoId && !usingVideoTag) {
            var activatedVideoElement = document.getElementById(activatedVideoId);
            if (activatedVideoElement) {
                activatedVideoElement.parentNode.removeChild(activatedVideoElement);
            }
        }
    }

    function _runEndStreamTimeout() {
        var end = parseInt(liveStreamInfo.endTime, 10),
            now = Date.now() + options.timeshift;

        // Таймер до окончания трансляции
        if (end && now < end) {
            var endsec = ~~((parseInt(liveStreamInfo.endTime, 10) - now) / 1000),
                maxDelay = Math.pow(2, 31) - 1, // setTimeout max delay
                delay = Math.min(endsec * 1000 + 3000, maxDelay); // Обновляем плеер на 3 секунды позже, чтобы исключить попадание в ноль
            _activeEndTimeout = setTimeout(function() {
                retry(0, true);
            }, delay);
        }
    }

    // Останавливаем все видео кроме текущего (такое бывает когда заглушка трансляции меняется на плеер)
    if (_activePlayerId !== playerElementId) {
        stop();
    }
    // Останавливаем музыку, если это opengraph
    if (options.isExternalPlayer && isMusicPlaying()) {
        music.pause();
    }

    _activePlayerId = playerElementId;
    _activeContainerId = playerContainerId;
    _activePlayerOptions = options;
    _activeVideoElementId = activatedVideoId;

    // Удаляем флеш-плеер, если он есть
    if ($element.length && $element.prop('tagName').toLowerCase() === 'object') {
        $element.remove();
        $element.length = 0;
    }

    // Создаём элемент вместо которого будет вставлен плеер и вставляем его после контейнера
    if (!$element.length && _isPurchasedOrFree(options)) {

        // Для внешних плееров и в случаях когда наужно сразу воспроизвести,
        // рисуем плеер сразу. Иначе рисуем после одного из событий плеера (см. __videoPlayerEvent)
        var showImmediately = !options.useEventsForShowing || options.isExternalPlayer || !options.isHtml5Player || !!options.flashvars.autostart;

        $container.toggleClass('invisible', showImmediately);
        var _playerContainerClass = showImmediately ? 'vid-card_player' : 'vid-card_player __hidden';

        var $playerContainer = $('<div>', {'id': playerElementId, 'class': _playerContainerClass}).insertAfter($container);
        $('<img/>', {'width': '100%', 'src': options.poster, 'class': 'vid-card_img vid-card_img-delayed'}).appendTo($playerContainer);
    }

    if (isEmptyPlayer) {
        // Пустой плеер всегда ONLINE
        status = MovieStatus.ONLINE;
    } else if (liveStreamInfo && status === MovieStatus.ONLINE && !hasLiveHLS && !hasLiveDash && !hasLiveWebrtc && !hasLiveStream) {
        // Волшебный глюк когда у трансляции статус OK, а ссылок трансляции нет
        status = MovieStatus.OFFLINE;
    }

    switch (status) {
        case MovieStatus.OK:
        case MovieStatus.ONLINE:
        case MovieStatus.COPYRIGHTS_RESTRICTED:
        case MovieStatus.BLOCKED:
        case MovieStatus.CENSORED:
            // Запускаем таймер на окончание трансляции
            !isEmptyPlayer && liveStreamInfo && _runEndStreamTimeout();
            if (!paymentStatus || paymentStatus === 'PAID') {
                // Запускаем плеер
                _playVideo();
            } else {
                _renderPlayerError(options, playerContainerId, playerElementId, status, statusText);
            }
            break;

        case MovieStatus.OFFLINE:
        case MovieStatus.LIVE_NOT_STARTED:
        case MovieStatus.LIVE_ENDED:
        case MovieStatus.LIVE_INTERRUPTED:
            if (liveStreamInfo) {
                // Показываем заглушку трансляции
                _embedLiveStub(options, playerContainerId, playerElementId);
            } else {
                // Показываем ошибку
                _renderPlayerError(options, playerContainerId, playerElementId, status, statusText);
            }
            break;

        default:
            // Показываем ошибку
            _renderPlayerError(options, playerContainerId, playerElementId, status, statusText);
    }
}

/**
 * Generate player container id.
 * @param {string} playerId
 * @return {string}
 */
function createPlayerContainerId(playerId) {
    return playerId + 'C';
}

/**
 * Generate player element id.
 * @param {string} playerId
 * @return {string}
 */
function createPlayerElementId(playerId) {
    return playerId + 'E';
}

/**
 * Render player.
 * @param {{}} options
 * @param {string=} playerContainerId
 * @param {string=} playerElementId
 * Параметры:
 * options {
 *     playerId - ID DOM элемента
 *     url - ссылка на плеер
 *     width - ширина
 *     height - высота
 *     flashvars = {
 *         movieId NOTNULL может быть строкой для Шары или для Uploaded, либо long - если это ролик
 *         voted - голосовал ли пользователь за это видео или нет
 *         metaUrlPrefix - url-префикс для пользовательского видео
 *         statId long nullable - ID ролика по которому вести статистику
 *         statUrl string nullable - ссылка по которой дергать статистику
 *     }
 * }
 */
function start(options, playerContainerId, playerElementId) {
    playerContainerId = playerContainerId || createPlayerContainerId(options.playerId);
    playerElementId = playerElementId || createPlayerElementId(options.playerId);

    // Сдвиг пользовательского времени относительно серверного (дублируем для видео, открытых не через h-mod)
    if (!options.timeshift) {
        options.timeshift = options.timestamp ? parseInt(options.timestamp, 10) - Date.now() : 0;
    }

    disableLoader(playerContainerId);

    _preactivateVideo(options, document.getElementById(playerContainerId), function(activatedVideoId) {
        _getExistsMetadataAndRun(options, function () {
            _embedPlayer(options, playerContainerId, playerElementId, activatedVideoId);
        }, function () {
            _renderPlayerError(options, playerContainerId, playerElementId, STATUS_NOT_FOUND);
        });
    });
}

function extractOptions(node) {
    return JSON.parse(node.getAttribute('data-options'));
}

function extractPlayerContainerId(node) {
    return node.getAttribute('data-player-container-id')
}

function extractPlayerElementId(node) {
    return node.getAttribute('data-player-element-id');
}

/**
 * Удаляет видео
 */
function stop() {
    if (_activePlayerId && _activeContainerId) {
        _stopTimers();
        $('#' + _activePlayerId).remove();
        $('#' + _activeContainerId).removeClass('invisible');
        $('#' + _activeVideoElementId).remove();
        _activePlayerId = null;
        _activeContainerId = null;
        _activeVideoElementId = null;
        _activePlayerOptions = {};
    }
}

/**
 * Останавливает воспроизведение текущего плеера (если это не мини-плеер)
 * @param {boolean=} force
 */
function pause(force) {
    if (force || !hasMiniPlayer()) {
        _callPlayer('togglePlay', false);
    }
}

/**
 * Возобновляет воспроизведение текущего плеера
 */
function play() {
    _callPlayer('togglePlay', true);
}

/**
 * Устанавливает громкость воспроизведения
 * @param {Number} value
 */
function volume(value) {
    _callPlayer('setVolume', value);
}

/**
 * Mute/unmute
 * @param {boolean} isMuted
 */
function setMuted(isMuted) {
    _callPlayer('toggleMute', isMuted);
}

/**
 * Изменяет ориентацию плеера
 */
function orientation(degree) {
    _callPlayer('rotate', degree);
}

/**
 * Перематывает к указанной позиции
 * @param {number} time
 * @param {boolean=} force
 */
function seek(time, force) {
    _callPlayer(force ? 'seekTime' : 'seek', time);
}

/**
 * Перематывает к указанной позиции
 * @param {{}} annotations
 */
function changeAnnotations(annotations) {
    _callPlayer('changeAnnotations', annotations);
}

/**
 * Действия звонка в эфир
 */
function callToStream() {
    _callPlayer('callToStream', Array.prototype.slice.call(arguments));
}

function showTooltip(timeSec) {
    _callPlayer('showProgressTip', timeSec);
}

function hideTooltip() {
    _callPlayer('hideProgressTip', null);
}


/**
 * Переинициализирует плеер
 * @param {number=} timeout
 * @param {boolean=} isSmall
 * @param {boolean=} blockWebrtc
 */
function retry(timeout, isSmall, blockWebrtc) {
    if (blockWebrtc) {
        _activePlayerOptions.blockWebrtc = blockWebrtc;
    }
    _activeRetryTimeout = setTimeout(_getMetadataAndRun.bind(
        null,
        _activePlayerOptions,
        function() {
            _embedPlayer(_activePlayerOptions, _activeContainerId, _activePlayerId, _activeVideoElementId);
        },
        function() {
            _renderPlayerError(_activePlayerOptions, _activeContainerId, _activePlayerId, STATUS_NOT_FOUND);
        },
        isSmall
    ), timeout || 0);
}

/**
 * Получает элемент плеера
 * @return {HTMLElement}
 */
function getPlayer() {
    return _activePlayerId ? document.getElementById(_activePlayerId) : null;
}

function disableLoader(playerContainerId) {
    if (!playerContainerId || typeof loaderInfo !== 'object') {
        return;
    }

    var info = loaderInfo[playerContainerId]
    if (!info || !info.loaderEnabled || !info.element || !info.element.querySelector) {
        return;
    }

    var loader = info.element.querySelector('.js-video-loader');
    if (loader) {
        loader.classList.toggle('invisible', true);
    }
}

/**
 * Получает ID активного плеера
 * @return {string}
 */
function getPlayerId() {
    return _activePlayerId;
}

/**
 * Определяет, играет ли плеер сейчас
 * @return {boolean}
 */
function isPlaying() {
    var player = getPlayer();
    return player && player.isPlaying && player.isPlaying();
}

/**
 * Определяет, было ли запущено видео
 * @return {boolean}
 */
function isStarted() {
    var player = getPlayer();
    return player && player.isStarted && player.isStarted();
}

/**
 * Определяет, полностью ли завершено воспроизведение
 * @return {boolean}
 */
function isEnded() {
    var player = getPlayer();
    return player && player.isEnded && player.isEnded();
}

/**
 * Определяет, находится ли плеер в беззвучном режиме
 * @return {boolean}
 */
function isSilent() {
    var player = getPlayer();
    return player && player.isSilent && player.isSilent();
}

/**
 * Получает ID карды активного плеера
 * @return {string}
 */
function getContainerId() {
    return _activeContainerId;
}

/**
 * Получает контейнер активного плеера
 * @return {HTMLElement|null}
 */
function getPlayerContainer() {
    return _activeContainerId ? document.getElementById(_activeContainerId) : null;
}

/**
 * Получает обёртку контейнера активного плеера
 * @return {HTMLElement|null}
 */
function getPlayerContainerWrapper() {
    return getPlayerContainer() ? getPlayerContainer().parentNode : null;
}

/**
 * Получает настройки активного плеера
 * @return {{}}
 */
function getOptions() {
    return _activePlayerOptions;
}

/**
 * Get options and render video player.
 * @param {string} movieId
 * @param {string} playerId
 * @param {string} width
 * @param {string} height
 * @param {string} location
 * @param {{}=} options
 */
function createVideoPlayer(movieId, playerId, width, height, location, options) {
    _getPlayerAttributes(movieId, location).always(function(data) {
        data = data || {flashvars: {}};
        data.playerId = playerId || data.playerId || null;
        data.width = width || data.width || null;
        data.height = height || data.height || null;

        var error = (typeof data.error === 'string') ? data.error : '',
            playerContainerId = createPlayerContainerId(data.playerId),
            playerElementId = createPlayerElementId(data.playerId);

        if (error || !data.url) {
            _renderPlayerError(data, playerContainerId, playerElementId, STATUS_NOT_FOUND);
            return;
        }

        if (isMusicPlaying()) {
            // Если играет музыка, превью показываем без звука и контролов
            data.flashvars.silent = '1';
        }

        if (options) {
            data = $.extend(true, data, options);
        }
        start(data, playerContainerId, playerElementId);
    });
}

function isMusicPlaying() {
    return music.playing();
}

/**
 * Определяет, открыт ли мини-плеер
 * @returns {boolean}
 */
function hasMiniPlayer() {
    return _activePlayerId === MINI_PLAYER_ID;
}

var ALF_REQUEST_TIMEOUT = 3000;

/**
 * Функция получения модуля OK/alf - он отвечает за отстук рекламной статистики в ленте и
 * частично борется с блокировщиками рекламы. Сделано получение через promise, чтобы избежать возможных проблем,
 * если OK/alf не загрузился (блокировщики рекламы могут забанить)
 */
var getAlf = function() {
    var result = new Promise(function(resolve, reject) {

        function log(msg) {
            logger.success('banner.feed', 'alf-okvideo', msg);
        }

        // на всякий случай, через 3 секунды делаем reject
        var timeout = setTimeout(
            function() {
                log('timeout');
                reject();
            },
            ALF_REQUEST_TIMEOUT
        );

        import('OK/alf').then(m => m && m.default || m).then(function (alf) {
                log('load');
                clearTimeout(timeout);
                resolve(alf);
            },
            function() {
                log('error');
                clearTimeout(timeout);
                reject();
            }
        );
    });

    getAlf = function () {
        return result;
    };

    return result;
};

/**
 *
 * Получение пикселей для отстука статистике по рекламынм видео
 *
 * @param {jQuery} $element
 */
function getPixels($element) {
    var $bannerWrapper = $element.closest('.js-video-scope'),
        pixelData;

    if ($bannerWrapper.length) {
        if ($bannerWrapper.data('id')) {
            // в случае если есть ссылка на баннер (например если он в ленте), то возьмём его
            $bannerWrapper = $('#' + $bannerWrapper.data('id'));
        }

        if ($bannerWrapper.length) {
            pixelData = $bannerWrapper.children('.js-video-pixels').data('pixelsjson');
        }
    }

    if (pixelData) {
        return Promise.resolve(convertPixels(pixelData));
    }

    return getAlf()

    .then(function(alf) {
        return alf.findAdv($element[0]);
    })

    .then(function (adv) {
        pixelData = adv.getVideoPixels();

        if (pixelData) {
            return convertPixels(pixelData);
        }

        return {};
    });
}

function convertPixels(pixelData) {
    var pixels = {};

    var _pushPixel = function(name, url) {
        if (!pixels.hasOwnProperty(name)) {
            pixels[name] = [];
        }
        pixels[name].push(url);
    };

    try {
        for (var i in pixelData) {
            if (pixelData.hasOwnProperty(i)) {
                var pixel = pixelData[i];
                if (pixel.type === 'playheadReachedValue') {
                    // Приводим пиксели к виду playheadReachedValuePercent23 и playheadReachedValueSecond86
                    if (pixel.hasOwnProperty('pvalue')) {
                        _pushPixel(pixel.type + 'Percent' + pixel.pvalue, pixel.url);
                    } else if (pixel.hasOwnProperty('value')) {
                        _pushPixel(pixel.type + 'Second' + pixel.value, pixel.url);
                    }
                } else {
                    _pushPixel(pixel.type, pixel.url);
                }
            }
        }
    } catch (e) {
        // Не удалось распарсить пиксели, ничего не делаем
    }

    return pixels;
}

function activate(element) {
    var $element = $(element),
        playerContainerId = extractPlayerContainerId(element), // ID элемента, который будет заменён плеером
        playerElementId = extractPlayerElementId(element), // ID с которым будет создан плеер
        options = extractOptions(element), // Дальше везде клонируем options, чтобы в каждом объекте был собственный экземпляр
        autostart = $element.data('autostart'), // Нужно ли запускать видео сразу
        viewportTimeout = $element.data('viewportTimeout'),
        viewportStartPolicy = $element.data('viewportPolicy'),
        isBannerVideo = $element.data('isBannerVideo') || ($element[0].getAttribute('data-is-fla') === 'true'),
        additionalLogging = $element.data('additionalLogging'),
        visiblePart = $element.data('visiblePart'),
        useEventsForShowing = $element.data('useEventsForShowing'),
        isObserverEnabled = $element.data('observer');

    // Сдвиг пользовательского времени относительно серверного
    options.timeshift = options.timestamp ? parseInt(options.timestamp, 10) - Date.now() : 0;
    options.flashvars.isBanner = isBannerVideo;
    options.useEventsForShowing = useEventsForShowing;

    var loaderEnabled = $element.data('video-loader-enabled') === true;
    if (typeof loaderInfo === 'object' && loaderEnabled && playerContainerId) {
        loaderInfo[playerContainerId] = {
            element: element,
            loaderEnabled: true
        }
    }

    function initPlayer() {
        // Добавляем контейнер, если его нет
        if (!$element.find('#' + playerContainerId).length) {
            $('<div>', {'id': playerContainerId, 'class': 'vid-card_cnt_w'})
                .css({'width': '100%', 'height': '100%'})
                .append($('<div>', {'class': 'vid_play'}))
                .appendTo($element);
        }

        // Клик по карде видео в ленте
        $element.find('#' + playerContainerId).on('click', function (e) {
            e.preventDefault();

            import('OK/StickyPlayer').then(m => m && m.default || m).then(function (StickyPlayer) {
                if (StickyPlayer.isActive()) {
                    StickyPlayer.updateView({
                        reset: true
                    });
                }
            });

            _preactivateVideo(options, document.getElementById(playerContainerId), function (activatedVideoId) {
                _getExistsMetadataAndRun(options, function () {
                    if (_isPurchasedOrFree(options)) {
                        _embedPlayer(options, playerContainerId, playerElementId, activatedVideoId);
                    } else {
                        _openPayment(options, playerContainerId, playerElementId, activatedVideoId);
                    }
                }, function () {
                    _renderPlayerError(options, playerContainerId, playerElementId, STATUS_NOT_FOUND);
                });
            });
        });

        // Автоматическое воспроизведение
        if (autostart) {
            start($.extend(true, {'flashvars': {'autostart': 1}}, options), playerContainerId, playerElementId);
        }

        // Политики автоплея
        if (viewportTimeout && viewportStartPolicy && viewportStartPolicy.policy === 'NO_AUTOSTART') {
            viewportTimeout = null;
        }

        // Автоматическое воспроизведение по попадании во вьюпорт (только для наших плееров)
        if (isObserverEnabled && viewportTimeout && !options.isExternalPlayer) {
            import('OK/VideoAutoplayObserverFlow').then(m => m && m.default || m).then(function (VideoAutoplayFlow) {
                VideoAutoplayFlow.videoAutoplayObserverFlow.activateObserverPlayer({
                    options: extendDeep({}, options),
                    playerContainerId,
                    playerElementId,
                    visiblePart,
                    viewportStartPolicy,
                    additionalLogging,
                });
            });
        } else if (viewportTimeout && !options.isExternalPlayer) {
            import('OK/VideoAutoplayFlow').then(m => m && m.default || m).then(function (VideoAutoplayFlow) {
                VideoAutoplayFlow.activateViewportPlayer($.extend(true, {}, options), playerContainerId, playerElementId, viewportTimeout, visiblePart, viewportStartPolicy, additionalLogging);
            });
        }

        var scope = _findScope(element);
        if (scope !== undefined) {
            scope.videoCard = {
                seek: function (position) {
                    if (_activePlayerId === null) {
                        start($.extend(true, {'flashvars': {'fromTime': position}}, options), playerContainerId, playerElementId);
                    } else {
                        _callPlayer('seekTime', position);
                    }
                }
            }
        }
    }

    var lang = options.flashvars.locale || 'ru';
    import('PTS/video.player/' + lang).then(m => m && m.default || m).then(function(locales) {
        translations = locales['video.player'];

        if (isBannerVideo && (options.flashvars.videoPixelsSupportByAlf !== '1')) {
            // Получаем рекламные пиксели
            getPixels($element).then(
                function (pixels) {
                    if (Object.keys(pixels).length > 0) {
                        options.flashvars.pixels = pixels;
                    }
                    initPlayer();
                },
                initPlayer
            )
        } else {
            initPlayer();
        }
    });
}

function deactivate(element) {
    var $element = $(element),
        playerContainerId = $element.data('playerContainerId'),
        viewportTimeout = $element.data('viewportTimeout'),
        isObserverEnabled = $element.data('observer');

    if (isObserverEnabled && viewportTimeout) {
        import('OK/VideoAutoplayObserverFlow').then(m => m && m.default || m).then(function (VideoAutoplayFlow) {
            VideoAutoplayFlow.videoAutoplayObserverFlow.deactivateObserverPlayer(playerContainerId);
        });
    } else if (viewportTimeout) {
        import('OK/VideoAutoplayFlow').then(m => m && m.default || m).then(function (VideoAutoplayFlow) {
            VideoAutoplayFlow.deactivateViewportPlayer(playerContainerId);
        });
    }

    if (playerContainerId === _activeContainerId) {
        stop();
    }
    $element.off();

    if (typeof loaderInfo === 'object' && playerContainerId in loaderInfo) {
        delete loaderInfo[playerContainerId];
    }
}

function _findScope(element) {
    var scope = element.parentNode;
    var maxLevels = 30;
    while (scope && scope !== document && --maxLevels > 0) {
        if (scope.classList.contains('js-video-scope')) {
            return scope;
        }
        scope = scope.parentNode;
    }
}

function onTimestampClick(e) {
    var scope = _findScope(e.target);
    var videoCard = scope.videoCard;
    if (videoCard !== undefined) {
        videoCard.seek(parseInt(e.target.getAttribute('data-position'), 10));
    }
}

/**
 *
 * @param {HTMLElement} element элемент с видео-плеером
 * @param {string} timeSec
 */
function onTimestampChange(element, timeSec) {
    var videoCard = element.videoCard;
    if (videoCard !== undefined) {
        videoCard.seek(parseInt(timeSec, 10));
    }
}

function initTimestamp(element) {
    element.addEventListener('click', onTimestampClick);
}

function destroyTimestamp(element) {
    element.removeEventListener('click', onTimestampClick);
}

export default { activate, deactivate, createVideoPlayer, start, stop, pause, play, retry, volume, setMuted, orientation, seek, hasMiniPlayer, getPlayer, getPlayerId, getContainerId, getPlayerContainer, getPlayerContainerWrapper, getOptions, prolongPayment, isPlaying, isStarted, isEnded, isSilent, createPlayerContainerId, createPlayerElementId, initTimestamp, destroyTimestamp, onTimestampChange, changeAnnotations, callToStream, showTooltip, hideTooltip, extractOptions, extractPlayerElementId, extractPlayerContainerId };

export { activate, deactivate, createVideoPlayer, start, stop, pause, play, retry, volume, setMuted, orientation, seek, hasMiniPlayer, getPlayer, getPlayerId, getContainerId, getPlayerContainer, getPlayerContainerWrapper, getOptions, prolongPayment, isPlaying, isStarted, isEnded, isSilent, createPlayerContainerId, createPlayerElementId, initTimestamp, destroyTimestamp, onTimestampChange, changeAnnotations, callToStream, showTooltip, hideTooltip, extractOptions, extractPlayerElementId, extractPlayerContainerId };
